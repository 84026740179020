body {
    min-height: 100vh;
    background-image: url("./media/logo/smallLogoWhiteFooter.svg");
    background-repeat: initial;
    background-size: 40px;
}

footer {
    background-image: url("./media/logo/smallLogoFooter.svg");
    background-repeat: initial;
    background-size: 150px;
    background-color: #c0ab8c;
}

button:focus{
    outline: none;
}


p, h1, h2, h3, h4, h5, h6, strong, em, del, ul, ol, li {
    color: inherit;
    font-weight: inherit;
}

p {
    font-size: 17px;
}

h1 {
    font-size: 60px;
    line-height: 60px;
    font-family: "Playfair+Display", serif;
}

h2 {
    font-size: 27px;
}

h3 {
    font-size: 25px;
}

h4 {
    font-size: 23px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 17px;
}

@media (max-width: 600px) {
    p {
        font-size: 16px;
    }

    h1 {
        font-size: 45px;
        line-height: 45px;
        font-family: "Playfair+Display", serif;
    }

    h2 {
        font-size: 25px;
    }

    h3 {
        font-size: 23px;
    }

    h4 {
        font-size: 21px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 15px;
    }
}

@media (max-width: 400px) {
    p {
        font-size: 15px;
    }

    h1 {
        font-size: 30px;
        line-height: 30px;
        font-family: "Playfair+Display", serif;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 15px;
    }

    h6 {
        font-size: 12px;
    }
}

.cookies p, .cookies h1, .cookies h2, .cookies h3, .cookies h4, .cookies h5, .cookies h6 {
    font-size: 13px;
    font-family: sans-serif;
}
.cookies button {
    text-transform: unset;
    font-size: 11px;
    font-weight: bold;
    padding: 15px 10px;
}

.textWhite > * {
    color: #ffffff !important;
}

.margin-0 > * {
    margin: 0 !important;
}

.margin-5 > * {
    margin: 5px !important;
}

.titleMargin-0 > h1, h2, h3, h4, h5, h6 {
    margin: 0 !important;
}

.extraMargin-p > p {
    margin-block-start: 2em;
    margin-block-end: 2em;
}

.titleContrast > p, .titleContrast > h2, .titleContrast > h3, .titleContrast > h4, .titleContrast > h5, .titleContrast > h6, .titleContrast > strong, .titleContrast > em, .titleContrast > del, .titleContrast > ul, .titleContrast > ol, .titleContrast > li {
    color: #899194;
}

.grayColor{
    color: #899194
}

.p-gray > p{
    color: #899194
}

.linkContrast > p > a {
    text-decoration: underline !important;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

.dec a {
    text-decoration: none;
    color: inherit;
}

.dec a:hover {
    text-decoration: underline;
    color: inherit;
}

.aws-btn {
    --slider-height-percentage: 60%;
    --slider-transition-duration: 525ms;
    --organic-arrow-thickness: 1px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 23px;
    --organic-arrow-color: #d6b18f;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #fcd0a8;
    --control-bullet-active-color: #d6b18f;
    --loader-bar-color: #fff0e9;
    --loader-bar-height: 6px;
}

.BrainhubCarousel__arrows{
    background-color: transparent !important;
}

/*------------------- DatePicker -------------------------*/
.react-datepicker {
    background-color: rgb(241, 241, 241) !important;
    border: none !important;
    padding-right: 80px !important;
}

.react-datepicker__triangle{
    border-bottom-color:#C0AB8C !important;
}

.react-datepicker__header{
    background-color: #C0AB8C !important;
    border-bottom: none !important;
}

.react-datepicker__current-month, 
.react-datepicker__day-name, 
.react-datepicker-time__header {
    color: white !important;
}

.react-datepicker__current-month, 
.react-datepicker-time__header {
    padding: 10px 0 !important;
}

.react-datepicker__navigation--previous {
    border-right-color: white !important;
}

.react-datepicker__navigation--next {
    border-left-color: white !important;
}

.react-datepicker__navigation:focus {
    outline: none !important;
}

/* .react-datepicker__month {
    padding: 10px !important;
} */


.react-datepicker__day,
.react-datepicker__day-name {
    width: 2.5rem !important;
    line-height: 2.5rem !important;
}

.react-datepicker__day--outside-month {
    color: rgb(128, 128, 128) !important;
}

.react-datepicker__day--selected {
    background-color: #C0AB8C !important;
}

            /* TIME */
.react-datepicker__time-container {
    border-left: none !important;
    float: none !important;
    position: absolute !important;
    right: 0 !important;
    height: 100% !important;
}

.react-datepicker__time-box,
.react-datepicker__time-container {
    width: 80px !important;
}

.react-datepicker__time{
    background-color: rgb(221, 221, 221) !important;
}

.react-datepicker__time-list-item {
    height: 25px !important;
    padding-top: 15px !important;
}

.react-datepicker__time-list-item--selected {
    background-color: #C0AB8C !important;
}

.react-datepicker__time-list-item--disabled {
    color: rgb(156, 156, 156) !important;
}



@media (max-width: 470px), (max-height: 550px) {
    .react-datepicker__day,
    .react-datepicker__day-name {
        width: 1.75rem !important;
        line-height: 2rem !important;
    }
}


@media (max-width: 340px) {
    .react-datepicker {
        padding-right: 65px !important;
    }
    .react-datepicker__time-box,
    .react-datepicker__time-container {
        width: 65px !important;
    }
}